/*global PayMaya*/
import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useAlert } from 'react-alert';
import { useQueryParam, StringParam } from 'use-query-params';
import axios from 'axios';
import ClipLoader from "react-spinners/RiseLoader";
import icon_success from './success_icon.svg';
import './App.css';


function App() {
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [isEnoughInfo, setIsEnoughInfo] = useState(false);
  const [header_auth] = useQueryParam('header_auth', StringParam);
  const [gwOrderid] = useQueryParam('gwOrderid', StringParam);
  const [authenUrl] = useQueryParam('authenUrl', StringParam);
  const [action_page] = useQueryParam('action_page', StringParam);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    setIsLoading(true);
    axios.post(action_page, {
      card: {
        number: data.cardNumber,
        cvc: data.cardCVC,
        expMonth: data.cardExpMonth,
        expYear: data.cardExpYear
      }
    }, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": header_auth
      }
    })
      .then(function (response) {
        console.log(response);
        const token = response.data.paymentTokenId;
        console.log(token);
        console.log(gwOrderid);

        axios.get(authenUrl, {
          params: {
            "token": token,
            "gwOrderid": gwOrderid
          }
        })
          .then(function (dataPayment) {
            console.log(dataPayment);
            window.location.href = dataPayment.data.response.verificationUrl;
          })
          .catch(function (error) {
            console.log(error);
            alert.show(`Processing error code: 001`, { type: 'error' });
          })
          .then(function () {
            setIsLoading(false)
            // always executed
          });
      })
      .catch(function (data) {
        console.log(data);
        setIsLoading(false)
        if (data.response) {
          if (data.response.data && data.response.data.message) {
            alert.show(`${data.response.data.message}. Code: ${data.response.data.code}`, { type: 'error' });
          } else if (data.response.data && data.response.data.parameters) {
            alert.show(data.response.data.parameters.description, { type: 'error' });
          }
        } else {
          alert.show('Unknown Error', { type: 'error' });
        }
      }).then(function () {
        // always executed
      });
  };

  console.log(watch("example"));

  useEffect(async () => {
    if (!header_auth) {
      alert.show('Please provide header_auth!', { type: 'error' })
      return;
    }
    if (!gwOrderid) {
      alert.show('Please provide gwOrderid!', { type: 'error' })
      return;
    }
    if (!authenUrl) {
      alert.show('Please provide authenUrl!', { type: 'error' })
      return;
    }
    if (!action_page) {
      alert.show('Please provide action_page!', { type: 'error' })
      return;
    }
    setIsEnoughInfo(true);
  }, []);

  return (
    <div className="App">
      {isEnoughInfo &&
        <div className="container">
          <form onSubmit={handleSubmit(onSubmit)}>
            <label>Card number</label>
            <input
              {...register("cardNumber", {
                required: true,
                minLength: 10,
                pattern: /^[0-9]+$/i
              })}
              type="number"
            />
            {errors?.cardNumber?.type === "required" &&
              <p>Card Number is required</p>
            }
            {errors?.cardNumber?.type === "minLength" && (
              <p>Card Number must be at least 10 digits</p>
            )}
            {errors?.cardNumber?.type === "pattern" && (
              <p>Number only</p>
            )}
            <label>CVC</label>
            <input {...register("cardCVC",
              {
                required: true,
                pattern: /^[0-9]+$/i
              })}
              type="number"
            />
            {errors?.cardCVC?.type === "required" &&
              <p>CVC is required</p>
            }
            {errors?.cardCVC?.type === "pattern" && (
              <p>Number only</p>
            )}
            <label>Expiration date</label>
            <div className="expiration-date">
              <div>
                <input {...register("cardExpMonth",
                  {
                    required: true,
                    minLength: 2,
                    maxLength: 2
                  })}
                  type="number"
                  placeholder="MM"
                />
                {errors?.cardExpMonth?.type === "required" &&
                  <p>Mothh is required</p>
                }
                {(errors?.cardExpMonth?.type === "minLength" || errors?.cardExpMonth?.type === "maxLength") && (
                  <p>Month must be 2 digits</p>
                )}
              </div>
              <span>/</span>
              <div>
                <input {...register("cardExpYear",
                  {
                    required: true,
                    minLength: 4,
                    maxLength: 4
                  })}
                  type="number"
                  placeholder="YYYY" />
                {errors?.cardExpYear?.type === "required" &&
                  <p>Year is required</p>
                }
                {(errors?.cardExpYear?.type === "minLength" || errors?.cardExpYear?.type === "maxLength") && (
                  <p>Year must be 4 digits</p>
                )}
              </div>
            </div>
            <input type="submit" />
          </form>
        </div>
      }
      {
        isLoading &&
        <div id="loading">
          <ClipLoader
            size={20}
            color="white"
          />
        </div>
      }
    </div>
  );
}

export default App;
